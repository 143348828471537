import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Footer({socials}) {
    return (
        <Container className="my-4">
            <Row className="d-flex justify-content-between align-items-center text-center">
                <Col md={4} sm={4} className="d-md-none mb-3">
                    <img
                        src="https://awjftp.blob.core.windows.net/awjcontents/websites/smoky/logo-footer.webp"
                        className="footer-logo"
                        alt="smokey logo"
                    />
                </Col>
                <Col md={4} sm={8}>
                    <Row className="text-center">
                        <Col md={12} className="mb-0">
                            <p className="headers-font danger fs-7 mb-2">OPENING HOURS FROM 12:00 PM TO 5:00 AM</p>
                        </Col>
                        <Col md={12} className="mb-0">
                            <p className="headers-font primary fs-6 mb-1">JUMEIRAH BEACH RESIDENCE, DUBAI</p>
                        </Col>
                        <Col md={12} className="mb-0">
                            <a href="https://maps.app.goo.gl/xKEKiM3owFdEuQmV8" className="headers-font secondary fs-7 mb-1">GET DIRECTIONS HERE</a>
                        </Col>
                    </Row>
                </Col>
                <Col md={4} sm={4} className="d-none d-sm-none d-md-block">
                    <img
                        src="https://awjftp.blob.core.windows.net/awjcontents/websites/smoky/logo-footer.webp"
                        className="footer-logo"
                        alt="smokey logo"
                    />
                </Col>
                <Col md={4} sm={12} className="d-flex justify-content-end d-none d-sm-none d-md-block">
                    <div className="d-flex justify-content-end align-items-center socials-footer">
                        <div className="me-5"><p className="headers-font primary m-0">@SMOKYBEACHDXB</p></div>
                        {
                            socials.map((item) => (
                                <div className="py-0 px-1 mx-1"><a href={item.link}><img src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/" + item.image} className="social-icon mt-n5" alt={item.title}/></a></div>
                            ))
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;