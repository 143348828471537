function Section1() {
  return (
    <div id="section1" className="section1 mt-scroll scroll-div" data-aos="fade-right">
      <div className="video-background">
        <video autoPlay muted loop>
          <source src="https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/website/smoky/smoky.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default Section1;