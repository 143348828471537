import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

function Section7({notify,warning}) {
  const initialState = {
    'name': '',
    'email': '',
    'msg': ''
  }
  const [formData, setFormData] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }
  async function handleSubmit(event) {
    event.preventDefault()
    let url = 'https://www-smokybeach.azurewebsites.net/contact/add-contact'
    const params = formData

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      const result = responseData.success
      if (result) {
        setFormData(initialState)
        notify("Your Email is sent.")
      }
      else {
        warning(responseData.body)
      }
    } catch (error) {
      console.error('Error while sending POST request:', error);
    }
  }
  return (
    <div id="section7" className="section7 mt-scroll py-5 scroll-div">
      <Container className="mw-700">
        <h2 className="form-header text-center primary mobile-mt-30">Get in Touch</h2>
        <Form className="text-center" onSubmit={handleSubmit}>
          <Form.Group className="form-labels mb-3" controlId="name">
            <Form.Label>FULL NAME</Form.Label>
            <Form.Control type="text" required name="name" onChange={handleChange} value={formData.name}/>
          </Form.Group>
          <Form.Group className="form-labels mb-3" controlId="email">
            <Form.Label>EMAIL ADDRESS</Form.Label>
            <Form.Control type="email" required name="email" onChange={handleChange} value={formData.email}/>
          </Form.Group>
          <Form.Group className="form-labels mb-3" controlId="msg">
            <Form.Label>MESSAGE / NOTE</Form.Label>
            <Form.Control as="textarea" rows={5} required name="msg" onChange={handleChange} value={formData.msg}/>
          </Form.Group>
          <div className="d-flex form-justify-div justify-content-start">
            <Form.Check
              type="checkbox"
              id={`default-checkbox`}
              className="form-checkbox col-md-4 col-sm-4 text-start"
              label={`I ACCEPT TO RECIEVE INFORMATION ABOUT PRODUCTS, SERVICES, DEALS OR RECOMMENDATIONS BY EMAIL`}
              required
            />
            <div className="col-4">
              <Button type="submit" variant="outline-light" size="sm" className="form-btn w-50">SUBMIT</Button>
            </div>
          </div>
        </Form>
      </Container>
    </div>
  );
}

export default Section7;