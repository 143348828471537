import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import ImageModal from './config/image-modal'

function Section5({ data }) {
    const [selectedData, setSelectedData] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const handleSelectedData = (datac) => {
        setSelectedData(datac)
        setShowModal(true)
    };

    const handleCloseModal = () => {
        setShowModal(false)
    }
    return (
        <div id="section5" className="section5 section4 mt-scroll pb-5 scroll-div">
            <Container>
                <Row className="text-center">
                    {
                        data.map((item) => (
                            <Col>
                                <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+item.image} className="side-image" data-aos="fade-up" data-aos-duration="2500" onClick={() => handleSelectedData("https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+item.image)} />
                            </Col>
                        ))
                    }
                </Row>
            </Container>
            {selectedData != null && <ImageModal image={selectedData} show={showModal} handleClose={handleCloseModal} />}
        </div>
    );
}

export default Section5;