import React, { useState, useEffect, useRef } from 'react'
import './design'
import './scripts'
/*React toastify library for notifications*/
import { ToastContainer, toast } from 'react-toastify'
import Placeholder from 'react-bootstrap/Placeholder'
import Header from './components/config/header'
import Footer from './components/config/footer'
import Socials from './components/config/socials-toolbar'
import Section1 from './components/section1'
import Section2 from './components/section2'
import Section3 from './components/section3'
import Section4 from './components/section4'
import Section5 from './components/section5'
import Section6 from './components/section6'
import Section7 from './components/section7'

function App() {
  const [loadingSection2, setLoadingSection2] = useState(true)
  const dataRefSection2 = useRef([])
  const [loadingSection3, setLoadingSection3] = useState(true)
  const dataRefSection3 = useRef([])
  const [loadingSection4, setLoadingSection4] = useState(true)
  const dataRefSection4 = useRef([])
  const [loadingSection5, setLoadingSection5] = useState(true)
  const dataRefSection5 = useRef([])
  const [loadingSection6, setLoadingSection6] = useState(true)
  const dataRefSection6 = useRef([])
  const [loadingSocials, setLoadingSocials] = useState(true)
  const dataRefSocials = useRef([])
  const notify = (MSG) => toast(MSG); //Function to show system's notification
  const warning = (MSG) => toast.error(MSG); //Function to show system's error notification

  useEffect(() => {
    fetch('https://www-smokybeach.azurewebsites.net/about')
      .then(response => response.json())
      .then(dataSection2 => {
        dataRefSection2.current = dataSection2;
        setLoadingSection2(false);
      })
      .catch(error => console.log(error))
    fetch('https://www-smokybeach.azurewebsites.net/our-menu')
      .then(response => response.json())
      .then(dataSection3 => {
        dataRefSection3.current = dataSection3;
        setLoadingSection3(false);
      })
      .catch(error => console.log(error))
    fetch('https://www-smokybeach.azurewebsites.net/outlet/all-outlets')
      .then(response => response.json())
      .then(dataSection4 => {
        dataRefSection4.current = dataSection4;
        setLoadingSection4(false);
      })
      .catch(error => console.log(error))
    fetch('https://www-smokybeach.azurewebsites.net/location-images/all-location-images')
      .then(response => response.json())
      .then(dataSection5 => {
        dataRefSection5.current = dataSection5;
        setLoadingSection5(false);
      })
      .catch(error => console.log(error))
    fetch('https://www-smokybeach.azurewebsites.net/social-media/all-social-media')
      .then(response => response.json())
      .then(dataSocials => {
        dataRefSocials.current = dataSocials;
        setLoadingSocials(false);
      })
      .catch(error => console.log(error))
    fetch('https://www-smokybeach.azurewebsites.net/slider/all-slider')
      .then(response => response.json())
      .then(dataSection6 => {
        console.log(dataSection6)
        dataRefSection6.current = dataSection6;
        setLoadingSection6(false);
      })
      .catch(error => console.log(error))

  }, []);
  return (
    <div className="App overflow-hidden">
      {
        loadingSocials ? (<div><Placeholder xs={12} size="lg" animation="glow"/></div>) : (<Header socials={dataRefSocials.current.body} />)
      }
      {
        loadingSocials ? (<div><Placeholder xs={12} size="lg" animation="glow"/></div>) : (<Socials data={dataRefSocials.current.body} />)
      }
      <Section1 />
      {
        loadingSection2 ? (<div><Placeholder xs={12} size="lg" animation="glow"/></div>) : (<Section2 data={dataRefSection2.current.body} />)
      }
      {
        loadingSection3 ? (<div><Placeholder xs={12} size="lg" animation="glow"/></div>) : (<Section3 data={dataRefSection3.current.body} />)
      }
      <div className="parallax">
        {
          loadingSection4 ? (<div><Placeholder xs={12} size="lg" animation="glow"/></div>) : (<Section4 data={dataRefSection4.current.body} />)
        }
        {
          loadingSection5 ? (<div><Placeholder xs={12} size="lg" animation="glow"/></div>) : (<Section5 data={dataRefSection5.current.body} />)
        }
      </div>
      {
        loadingSection6 ? (<div><Placeholder xs={12} size="lg" animation="glow"/></div>) : (<Section6 data={dataRefSection6.current.body} />)
      }
      <Section7 notify={notify} warning={warning} />
      {
        loadingSocials ? (<div><Placeholder xs={12} size="lg" animation="glow"/></div>) : (<Footer socials={dataRefSocials.current.body} />)
      }
      {/*Notification box configuration*/}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
