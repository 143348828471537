import React from 'react'
import { Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
function ImageModal(props) {
    const { image, show, handleClose } = props;
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body className="p-0">
                <div className="card border border-0">
                    <img src={image} className="card-img-top img-fluid" alt="..."></img>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default ImageModal