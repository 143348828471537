import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

function Section2({data}) {
    return (
        <div id="section2" className="section2 mt-scroll pt-5 pb-4 scroll-div">
            <Container fluid className="me-0">
                <Row>
                    <Col md={6} className="m-auto section2-1" data-aos="fade-up" data-aos-duration="2500">
                        <Image src="https://awjftp.blob.core.windows.net/awjcontents/websites/smoky/section2-text.webp" />
                        <p className="section-paragraph">{data.description}</p>
                    </Col>
                    <Col md={6} className="p-0">
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+data.image} className="side-image" data-aos="fade-up" data-aos-duration="2500" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Section2;