import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

function Header({ socials }) {
  const [isActiveMenu, setIsActiveMenu] = useState("section1")

  function handleActiveSection(event) {
    const sectionId = event.target.getAttribute('data-id')
    const element = document.getElementById("toggleMenu")
    var computedStyle = window.getComputedStyle(element);
    setIsActiveMenu(sectionId)
    if (computedStyle.display !== "none") {
      setTimeout(function () {
        element.click()
      }, 500)
    }
  }

  function handleMenuToggle() {
    var divElement = document.getElementById("header-nav");
    divElement.classList.toggle("mobile-header");
  }
  // Get all elements with the class 'scroll-div'
  const divs = document.querySelectorAll('.scroll-div');

  // Function to get the current div's id on scroll
  function getCurrentDivId() {
    let currentDivId = null;

    divs.forEach((div) => {
      const rect = div.getBoundingClientRect();
      const scrollPosition = window.innerHeight / 2; // Adjust the value as needed

      if (rect.top <= scrollPosition && rect.bottom >= scrollPosition) {
        currentDivId = div.id;
      }
    });

    return currentDivId;
  }

  // Listen for the 'scroll' event on the window
  window.addEventListener('scroll', () => {
    var currentId = getCurrentDivId();
    if (currentId === "section5" || currentId === "section6") currentId = "section4"
    setIsActiveMenu(currentId)
  });
  return (
    <Navbar id="header-nav" expand="lg" fixed="top" className="navbar bg-body-tertiary mobile-header">
      <Container>
        <Navbar.Toggle id="toggleMenu" aria-controls="basic-navbar-nav" onClick={handleMenuToggle} />
        <Navbar.Collapse id="basic-navbar-nav" className='collapse navbar-collapse d-lg-flex nav-list'>
          <Nav className="col-lg-5">
            <Nav.Link href="#section1" data-id="section1" className={isActiveMenu === "section1" ? "nav-link-active" : ""} onClick={handleActiveSection}>HOME</Nav.Link>
            <Nav.Link href="#section2" data-id="section2" className={isActiveMenu === "section2" ? "nav-link-active" : ""} onClick={handleActiveSection}>ABOUT US</Nav.Link>
            <Nav.Link href="#section3" data-id="section3" className={isActiveMenu === "section3" ? "nav-link-active" : ""} onClick={handleActiveSection}>MENU</Nav.Link>
            <Nav.Link href="#section7" data-id="section7" className={isActiveMenu === "section7" ? "nav-link-active" : ""} onClick={handleActiveSection}>CONTACT US</Nav.Link>
            <Nav.Link href="#section4" data-id="section4" className={isActiveMenu === "section4" ? "nav-link-active" : ""} onClick={handleActiveSection}>LOCATION</Nav.Link>
          </Nav>
          <Nav className="navbar-brand col-lg-3 me-0 d-none d-sm-none d-md-block">
            <img
              src="https://awjftp.blob.core.windows.net/awjcontents/websites/smoky/logo.webp"
              className="header-logo"
              alt="smokey logo"
            />
          </Nav>
          <Nav className="d-lg-flex col-lg-3 justify-content-lg-end d-none d-sm-none d-md-block">
            {
              socials.map((item) => (
                <Nav.Link href={item.link}><img src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/" + item.image} alt={item.title} className="social-icon"/></Nav.Link>
              ))
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;