function SocialsToolbar({ data }) {
    return (
        <div className="d-md-none z-1 position-fixed top-50 start-100 pe-5 translate-middle">
            {
                data.map((item) => (
                    <a href={item.link} className='d-block mb-3'><img src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+item.image} className="social-icon mt-n5" alt={item.title} /></a>
                ))
            }
        </div>
    );
}

export default SocialsToolbar;