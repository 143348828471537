import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

function Section3({data}) {
    return (
        <div id="section3" className="section3 mt-scroll scroll-div">
            <Container fluid className="me-0">
                <Row>
                    <Col md={6} className="px-0">
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+data.image} className="side-image" data-aos="fade-up" data-aos-delay="500" data-aos-duration="2500" />
                    </Col>
                    <Col md={6} className="m-auto section2-1" data-aos="fade-up" data-aos-delay="500" data-aos-duration="2500">
                        <h1 className="headers-font primary mb-3">{data.title}</h1>
                        <p className="section-paragraph">{data.description}</p>
                        <div className="mw-300">
                            <a href={data.foodLink} className="d-block pb-1 mb-3 mt-5 fs-5 links-font primary link1">SEE MENU HERE</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Section3;