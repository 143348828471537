import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Section4({data}) {
    return (
        <div id="section4" className="p-section4 section4 mt-scroll py-5 scroll-div">
            <Container>
                <Row className="text-center" data-aos="fade-up" data-aos-duration="2500">
                    <Col md={12} className="ps-0">
                    <pre><p className="headers-font primary fs-5">{data[0].address}</p></pre>
                    </Col>
                    <Col md={12} className="ps-0">
                        <p className="headers-font danger">{data[0].opening_hours}</p>
                    </Col>
                    <Col md={12} className="ps-0">
                        <a href={data[0].slug} className="headers-font primary">LOCATION</a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Section4;